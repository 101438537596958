<template>
  <div>
    <CCardHeader class="d-flex align-items-center justify-content-between">
      <span>{{ $t('creatives.media') }}</span>
      <CButton size="sm" color="info" @click="createNewRecord">
        <CIcon name="cil-plus" />&nbsp;{{ $t('ui.add_media') }}
      </CButton>
    </CCardHeader>
    <CCardBody>
      <CDataTable :fields="fields" :items="creativeMediaList" striped add-table-classes="section-table" sorter hover>
        <template #no-items-view>
          <h4 class="text-center pt-2 pb-2">{{ $t('ui.list_empty') }}</h4>
        </template>
        <template #approved="{ item }">
          <td>
            <CBadge :color="getBadge(item.approved)">
              {{ item.approved ? $t('ui.active') : $t('ui.inactive') }}
            </CBadge>
          </td>
        </template>
        <template #show_details="{ item }">
          <td>
            <CButton :title="$t('ui.delete')" size="sm" color="danger" @click.prevent="remove(item)">
              <CIcon name="cil-trash" />
            </CButton>
          </td>
        </template>
      </CDataTable>
    </CCardBody>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { GET_CREATIVE, EDIT_CREATIVE_MEDIA } from '@/store/action-types/creatives';
import { getBadge } from '@/helpers/utils';

export default {
  name: 'CreativeMediaList',
  props: {
    creativeItem: {
      type: Object,
      required: true,
    },
  },
  computed: {
    creativeMediaList() {
      return this.creativeItem.media;
    },
    creativeId() {
      return this.$route.params.id;
    },
    fields() {
      return [
        { key: 'id', label: this.$t('creatives.id') },
        { key: 'campaignId', label: this.$t('creatives.campaign_id') },
        { key: 'creativeId', label: this.$t('creatives.creative_id') },
        { key: 'userId', label: this.$t('creatives.user_id') },
        { key: 'type', label: this.$t('creatives.type') },
        { key: 'forMobile', label: this.$t('creatives.for_mobile') },
        { key: 'width', label: this.$t('creatives.width') },
        { key: 'height', label: this.$t('creatives.height') },
        { key: 'path', label: this.$t('creatives.path') },
        { key: 'approved', label: this.$t('creatives.approved') },
        { key: 'show_details', label: this.$t('ui.actions'), sorter: false },
      ];
    },
  },
  methods: {
    ...mapActions('creatives', [EDIT_CREATIVE_MEDIA, GET_CREATIVE]),
    getBadge(status) {
      return getBadge(status);
    },
    createNewRecord() {
      this.$router.push({
        name: 'creative-media-create',
        params: { id: this.creativeId },
      });
    },
    remove(media) {
      this.EDIT_CREATIVE_MEDIA({ id: media.id, model: { deleted: true } })
        .then(() => {
          this.$notify({
            group: 'main',
            title: this.$t('notifications.success'),
            type: 'success',
            text: this.$t('notifications.updated'),
          });
          this.GET_CREATIVE(this.creativeId);
        })
        .catch(() => {
          this.$notify({
            group: 'main',
            title: this.$t('notifications.error'),
            type: 'error',
            text: this.$t('notifications.updating_record_error'),
          });
        });
    },
  },
};
</script>
