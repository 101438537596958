<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardBody v-if="isLoading">
            <MbnSpinner />
          </CCardBody>

          <CCardBody v-if="error">
            <MbnErrorMessage />
          </CCardBody>

          <CreativeForm
            v-if="initialValues"
            :is-sending="isSending"
            :initial-values="initialValues"
            @formSubmit="onSubmit"
          />
        </CCard>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CreativeMediaList v-if="creative" :creative-item="creative" />
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import CreativeMediaList from '@/views/Creatives/CreativeMedia/CreativeMediaList';
import { mapState, mapActions } from 'vuex';
import { GET_CREATIVE, EDIT_CREATIVE } from '@/store/action-types/creatives';
import CreativeForm from './CreativeForm';
import MbnSpinner from '@/components/Spinner';
import MbnErrorMessage from '@/components/ErrorMessage';

export default {
  name: 'CreativeEdit',
  components: {
    CreativeForm,
    MbnSpinner,
    MbnErrorMessage,
    CreativeMediaList,
  },
  computed: {
    ...mapState('creatives', ['isLoading', 'isSending', 'error', 'creative']),
    recordId() {
      return this.$route.params.id;
    },
    initialValues() {
      if (!this.creative) {
        return null;
      }

      return {
        name: this.creative.name,
        campaignId: this.creative.campaignId,
        type: this.creative.type,
        active: this.creative.active,
        moderated: this.creative.moderated,
        approved: this.creative.approved,
        linkType: this.creative.linkType,
        cpm: this.creative.cpm,
        dateEnd: new Date(this.creative.dateEnd),
        dateStart: new Date(this.creative.dateStart),
        clickUrl: this.creative.clickUrl,
        media: this.creative.media,
        sportFeedMatchId: this.creative.sportFeedMatchId,
        sportFeedActive: !!this.creative.sportFeedMatchId,
      };
    },
  },
  created() {
    this.GET_CREATIVE(this.recordId);
  },
  methods: {
    ...mapActions('creatives', [GET_CREATIVE, EDIT_CREATIVE]),
    onSubmit(formData) {
      this.EDIT_CREATIVE({ id: this.recordId, formData })
        .then(() => {
          this.$notify({
            group: 'main',
            title: this.$t('notifications.success'),
            type: 'success',
            text: this.$t('notifications.updated'),
          });
          this.$router.push({ name: 'creatives' });
        })
        .catch(() => {
          this.$notify({
            group: 'main',
            title: this.$t('notifications.error'),
            type: 'error',
            text: this.$t('notifications.updating_record_error'),
          });
        });
    },
  },
};
</script>
